import React from 'react';
import Layout from '../../components/Layout';

export default () => (
  <Layout>
    <section>
      <h1>Thank you!</h1>
      <p>This is a custom thank you page for form submissions</p>
    </section>
  </Layout>
);
